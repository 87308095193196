body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* src/assets/fonts.css */
@font-face {
  font-family: 'Grotesk';
  src: url('./assets/fonts/hk/HKGrotesk-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-75%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.spinner .dot {
  animation: bounce 1.5s infinite;
}
